<template>
  <div>
    <Divider title="企业银行帐号">
      <div slot="right"></div>
    </Divider>
    <el-descriptions title="银行卡信息">
      <el-descriptions-item label="账户名称">
        {{
          mybankList.accountName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="账号号码">
        {{
          mybankList.accountCode
        }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- <el-table :data="mybankList" border style="width: 100%">
      <el-table-column label="平台名称">
        <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
      </el-table-column>
      <el-table-column prop="bank_line_no" label="联行号">
        <template slot-scope="scope">{{ scope.row.bank_line_no }}</template>
      </el-table-column>
      <el-table-column prop="account" label="帐号"></el-table-column>
      <el-table-column prop="balance" label="余额/元">
        <template slot-scope="scope">{{ scope.row.balance | money }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="viewInfo(scope.row)" v-access data-ctrl="rechargeButton">充值</el-button>
          <el-button type="text" @click="withDrawal" v-access data-ctrl="withdrawalButton">提现</el-button>
          <el-button
            type="text"
            @click="refresh(scope.row)"
            :loading="scope.row.refreshLoading"
            style="padding: 0"
            v-access
            data-ctrl="refreshButton"
          >刷新</el-button>
        </template>
      </el-table-column>
    </el-table>-->
  </div>
</template>
<script>
import { api } from '/src/api/base';

export const mybankAccountUpdate = api()('mybank.account.update.json');
export const MybankAccountList = api()('enterprise.account.info.json');
export default {

  components: {
    Divider: () => import('../../../components/divider.vue'),
  },
  props: {
    userCode: String,
    viewVisable: Boolean
  },
  data () {
    return {
      mybankList: [],
      bankInfo: {}
    }
  },
  mounted () {
    this.getMybankList()
  },
  methods: {
    async getMybankList () {
      const res = await MybankAccountList({
        code: this.userCode
      });
      // for (let i = 0; i < res.length; i++) {
      //   res[i].refreshLoading = false;
      // }
      this.mybankList = res;
    },
    withDrawal () {
      this.$message.info('开发中，敬请期待！');
    },
    viewInfo (row) {
      this.bankInfo = row;

      this.$emit('viewVisable', this.bankInfo);
    },
    async refresh (item) {
      for (let i = 0; i < this.mybankList.length; i++) {
        if (this.mybankList[i].account == item.account)
          this.mybankList[i].refreshLoading = true;
      }
      const res = await mybankAccountUpdate({
        subjectCode: this.userCode,
        platform: item.pay_platform
      });

      for (let i = 0; i < this.mybankList.length; i++) {
        if (this.mybankList[i].pay_platform === res.platformCode) {
          this.mybankList[i].balance = res.balance;
          this.mybankList[i].refreshLoading = false;
        }
      }
      this.$message({
        message: '刷新成功',
        type: 'success'
      });
    },
  },
}
</script>
<style lang="">
</style>